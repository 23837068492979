<template>
  <div class="custom-button-dropdown relative">
    <CustomButton
:shape="props.shape"
                  :type="props.type"
                  :size="props.size"
                  :icon-position="props.iconPosition"
                  :icon-size="props.iconSize"
                  :icon="props.icon"
                  :text-small="props.textSmall"
                  :hover-animation="props.hoverAnimation"
                  @click="toggleMenu"
    >{{ props.buttonLabel }}</CustomButton>
    <div v-if="menuIsShown" class="absolute right-0 z-10 rounded-[0.25rem] bg-white p-[0.25rem] ring-1 ring-gray-200" :class="[ props.size === 'md' ? 'top-[3.5rem]' : 'top-[2.75rem]' ]">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import CustomButton from '@components/vue-elements/CustomButton.vue';
import {onBeforeUnmount, onMounted, ref} from "vue";

const props = defineProps(['shape', 'type', 'size', 'iconPosition', 'iconSize', 'icon', 'textSmall', 'hoverAnimation', 'buttonLabel']);

const menuIsShown = ref(false);

const toggleMenu = () => {
  menuIsShown.value = !menuIsShown.value;
};

onMounted(() => {
  document.addEventListener('click', (event) => {
    if (!event.target.closest('.custom-button-dropdown')) {
      menuIsShown.value = false;
    }
  });
});

onBeforeUnmount(() => {
  document.removeEventListener('click', (event) => {
    if (!event.target.closest('.custom-button-dropdown')) {
      menuIsShown.value = false;
    }
  });
});
</script>